import React from "react";
import "./News.scss";

import marked from "marked";

import placeholder from "../../assets/images/news-placeholder-img.webp";
import { HashLink as Link } from "react-router-hash-link";

const News = (props) => {
  return (
    <div className="news-container">
      <div id={props.cardHeadline} className="hiddenTest" />
      <div
        className="news-img"
        style={{
          backgroundImage:
            props.cardImage !== undefined ? `url(${props.cardImage.fields.file.url})` : `url(${placeholder})`
        }}
      >
        {props.tagCategory !== undefined ? (
          <div className="tag" style={{ backgroundColor: props.tagColor }}>
            {props.tagCategory}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="news-text">
        <h1>{props.cardHeadline}</h1>
        <p dangerouslySetInnerHTML={{ __html: marked(props.cardText ? props.cardText : "") }} />
      </div>
      <div className="line" />
      <Link className="readMore" to={{ pathname: "/post", state: props }}>
        Läs mer
      </Link>
    </div>
  );
};

export default News;
