import React, { useState } from "react";
import "./Nav.scss";
/* import { Link } from "react-router-dom"; */
import { HashLink as Link } from "react-router-hash-link";
import marked from "marked";

/* import Logo from "../../assets/icons/ascenda logo blue.webp"; */
/* import seLang from "../../assets/icons/sweden.webp";
import usLang from "../../assets/icons/united-kingdom.webp"; */

const Nav = (props) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <nav>
      <ul>
        <Link to="/" onClick={() => window.scrollTo(0,0)}>
          <p id="logo-placeholder">ONEFORLIFE</p>
          {/* <img alt="nav-logo" src={Logo} /> */}
        </Link>

        <Link className="link" id="nav-about" to="/#about">
          {props.lang === "sv" ? "om oss" : "about"}
        </Link>

        <div id="dropdown" className="offers">
          <Link className="link" id="dropbtn" to="/#area">
            {props.lang === "sv" ? "erbjudanden" : "services"}
          </Link>
          <div id="dropdown-content">
            {props.areas.map((i) => (
              <Link
                key={i.sys.id}
                to={`/#${i.fields.title}`}
                dangerouslySetInnerHTML={{ __html: marked(i.fields.title) }}
              />
            ))}
          </div>
        </div>

        {props.useCases.length !== 0 ? (
          <div id="dropdown" className="customer-cases">
            <Link className="link" id="dropbtn" to="/#customer-cases">
              {props.lang === "sv" ? "Kundcase" : "Customer Cases"}
            </Link>
            <div id="dropdown-content">
              {props.useCases.map((i) => (
                <Link
                  key={i.sys.id}
                  to={`/#${i.fields.cardHeadline}`}
                  dangerouslySetInnerHTML={{ __html: marked(i.fields.cardHeadline) }}
                />
              ))}
            </div>
          </div>
        ) : (
          ""
        )}

        <Link className="link" id="nav-contact" to="/#contact">
          {props.lang === "sv" ? "kontakt" : "contact"}
        </Link>

{/*         <div className="lang-container">
          <span>|</span>
          <img
            className="lang-btn sv"
            onClick={props.langSv}
            style={{
              opacity: props.lang === "sv" ? "1" : ".4",
            }}
            src={seLang}
            alt=""
          />
          <span>|</span>
          <img
            className="lang-btn en"
            onClick={props.langEn}
            style={{ opacity: props.lang === "en-US" ? "1" : ".4" }}
            src={usLang}
            alt=""
          />
          <span>|</span>
        </div> */}
      </ul>

      <div className={open ? "menu open" : "menu closed"} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div
        className={open ? "menuOpen" : "menuClosed"}
        //eslint-disable-next-line
        onClick={(e) => (e.stopPropagation(), setOpen(false))}
      >
{/*         <div className="lang-container">
          <span>|</span>
          <img
            className="lang-btn sv"
            onClick={props.langSv}
            style={{ opacity: props.lang === "sv" ? "1" : ".4" }}
            src={seLang}
            alt=""
          />
          <span>|</span>
          <img
            className="lang-btn en"
            onClick={props.langEn}
            style={{ opacity: props.lang === "en-US" ? "1" : ".4" }}
            src={usLang}
            alt=""
          />
          <span>|</span>
        </div> */}
        <Link to="/#about">{props.lang === "sv" ? "om oss" : "about"}</Link>
        <Link to="/#area">{props.lang === "sv" ? "erbjudanden" : "services"}</Link>
        {props.useCases.length !== 0 ? (
          <Link className="link" id="dropbtn2" to="/#customer-cases">
            {props.lang === "sv" ? "Kundcase" : "Customer Cases"}
          </Link>
        ) : (
          ""
        )}
        <Link to="/#contact">{props.lang === "sv" ? "kontakt" : "contact"}</Link>
      </div>
    </nav>
  );
};

export default Nav;
