import React from "react";
import "./Footer.scss";

const Footer = (props) => {
  return (
    <div id="footer-container">
      <div id="footer-content">

        <div id="footer-address">
          <p>{props.address}</p>
          <p>{props.postnummer}</p>
          <p>{props.box}</p>
        </div>

        <div id="footer-contact">
          <a href="mailto:info@ascenda.se">{props.email}</a>
          <p>{props.phone}</p>
        </div>

        <p id="copyright">@Oneforlife 2020</p>
      </div>
    </div>
  );
};

export default Footer;
