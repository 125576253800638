import React from "react";
import "./About.scss";

import marked from "marked";

const About = (props) => {
  const imgPosition = () => {
    var position;
    if (props.left === true) {
      position = "l";
    }
    return position;
  };

  return (
    <div className="about-container">
      <h1 id="about-title">{props.title}</h1>
      <p id="about-desc" dangerouslySetInnerHTML={{ __html: marked(props.desc) }} />
      <img src={props.image} alt="" style={{ gridArea: imgPosition() }} />
    </div>
  );
};

export default About;
