import React, { useEffect } from "react";
import "./home.scss";

import Hero from "../../components/hero/Hero";
import About from "../../components/about/About";
import News from "../../components/news/News";
import AreasLevel1 from "../../components/areasLevel1/AreasLevel1";

const Home = (props) => {
  const setPosition = (index) => {
    for (var i = 0; i < props.areas.length; i += 4) {
      if (index === i || index === i + 1) {
        return true;
      }
    }
  };

  const sortItemsAlphabeticalOrder = (arr) => {
    arr.sort((a, b) => a.fields.title.localeCompare(b.fields.title));
  };

  useEffect(() => {
    sortItemsAlphabeticalOrder(props.areas);
  }, [props.areas]);

  /*   useEffect(() => {
    window.scrollTo(0, 0);
  }, []); */

  return (
    <div id="home-container">
      <Hero
        slogan={props.hero.map((i) => i.fields.slogan)}
        images={props.hero.map((i) => i.fields.backgroundImages)}
        placeholder={props.hero.map((i) => i.fields.placeholder)}
        imageTimer={props.hero.map((i) => i.fields.imageTimer)}
      />

      {props.news.length !== 0 ? (
        <div id="news">
          <div className="news-section">
            {props.news.slice(0, 3).map((i) => (
              <News
                key={i.sys.id}
                cardHeadline={i.fields.cardHeadline}
                cardText={i.fields.cardText}
                tagCategory={i.fields.tagCategory}
                tagColor={i.fields.tagColor}
                cardImage={i.fields.cardImage}
                video={i.fields.media}
                images={i.fields.images}
              />
            ))}
          </div>
        </div>
      ) : (
        <div />
      )}

      <div id="about-section">
        <div id="about" />
        <h1 id="about-header">Oneforlife</h1>
        {props.about.map((i) => (
          <About
            key={i.fields.title}
            title={i.fields.title}
            desc={i.fields.description}
            image={i.fields.image.fields.file.url}
            left={i.fields.leftAlignImage}
          />
        ))}
      </div>

      <div id="area">
        <div id="area-title">
          <h1>{props.lang === "sv" ? "Våra Erbjudanden" : "Our Services"}</h1>
        </div>
        <div id="areas">
          {props.areas.map((i, index) => (
            <AreasLevel1
              key={i.sys.id}
              id={i.fields.title}
              title={i.fields.title}
              desc={i.fields.shortDescription}
              icon={i.fields.icon.fields.file.url}
              position={setPosition(index)}
              ribbonTitle={i.fields.ribbonTextTitle}
              ribbonSubTitle={i.fields.ribbonTextSubTitle}
              ribbonImage={i.fields.ribbonTextImage !== undefined ? i.fields.ribbonTextImage.fields.file.url : ""}
              ribbonLink={i.fields.ribbonTextLink}
            />
          ))}
        </div>
      </div>

      {props.useCases.length !== 0 ? (
        <div id="useCaseHidden">
          <div id="customer-cases"/>
          <h1 id="useCase-header">{props.lang === "sv" ? "Kundcase" : "Customer Cases"}</h1>
          <div className="useCase-section">
            {props.useCases.map((i) => (
              <News
                key={i.sys.id}
                cardHeadline={i.fields.cardHeadline}
                cardText={i.fields.cardText}
                cardImage={i.fields.cardImage}
                tagCategory={i.fields.tagCategory}
                tagColor={i.fields.tagColor}
                bodyText={i.fields.bodyText}
              />
            ))}
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Home;
