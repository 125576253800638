import React from "react";
import "./AreasLevel1.scss";

import marked from "marked";

const AreasLevel1 = (props) => {
  var setLeftPosition = () => {
    if (props.position === true) {
      var titleColor = "#000";
      var textColor = "#4D4D4D";
      var bgColor = "white";
      var left = "l";
    }

    return [titleColor, textColor, bgColor, left];
  };

  return (
    <div className="expertises" /* id={props.id} */ style={{ backgroundColor: setLeftPosition()[2] }}>
      <div id={props.id} className="hiddenTest" />
      <div
        id="expertise-banner"
        style={{
          gridArea: setLeftPosition()[3],
          backgroundImage: `linear-gradient(${props.color1}, ${props.color2})`,
        }}
      >
        <div id="line" />
        <div className="icon">
          <img alt="" src={props.icon} />
        </div>
        <div id="line2" />
      </div>

      <h1
        className="title"
        style={{ color: setLeftPosition()[0] }}
        dangerouslySetInnerHTML={{ __html: marked(props.title) }}
      />
      <p
        className="desc"
        style={{ color: setLeftPosition()[1] }}
        dangerouslySetInnerHTML={{ __html: marked(props.desc) }}
      />

      <div
        id="ribbon-text"
        style={{
          gridArea: props.position ? "r" : "l",
          color: "white",
          backgroundColor: props.ribbonTitle !== undefined ? "" : "transparent",
          borderColor: props.ribbonImage !== "" ? "" : "transparent",
        }}
      >
        <h1>{props.ribbonTitle}</h1>
        <p>{props.ribbonSubTitle}</p>
        <img alt="" src={props.ribbonImage} />
        <a href="/">{props.ribbonLink}</a>
      </div>
      {/* <div /> */}
    </div>
  );
};

export default AreasLevel1;
