import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./UCNP.scss";
import marked from "marked";

const UCNP = () => {
  const { state } = useLocation();
  /* console.log(state); */
  const checkImage = () => {
    if(state.cardImage !== undefined || null) {
      return state.cardImage.fields.file.url;
    } else {
      return ""
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="post">
      <div
        className="post-header"
        style={{
          backgroundImage: `linear-gradient(
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.5)
        ), url(${checkImage()})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%" /* backgroundColor: state.tagColor */,
        }}
      >
        <div className="post-tag" style={{ backgroundColor: state.tagColor }} />
        <p className="post-tagText">{state.tagCategory}</p>
        <h1 className="post-headline">{state.cardHeadline}</h1>
        <p className="post-text">{state.cardText}</p>
      </div>

      <div className="post-container">
        {state.bodyText !== undefined || null ? <p dangerouslySetInnerHTML={{ __html: marked(state.bodyText) }} /> : ""}
      </div>
    </div>
  );
};

export default UCNP;
